// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Admin from "./components/Admin";
import AdminRegister from "./components/AdminRegister";
import Sidebar from "./components/Sidebar";
import AdminNext from "./components/SignIn";
import Home from "./components/Dashboard";
import CustomerRegister from "./components/CustomerRegister";
import CustomerVehicleAdd from "./components/CustomerVehicleAdd";
import CustomerBooking from "./components/CustomerBooking";
import Customer from "./components/RegisteredCustomer";
import BrandManager from "./components/BrandManager";
import ModelManager from "./components/ModelManager";
import ManageServicePackages from "./components/ManageServicePackages";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import SearchCustomer from "./components/CustomerList";
import Unauthorized from "./components/Unauthorized";
import AllBrand from "./components/AllBrand";
import BillingPage from "./components/BillingPage";
import Biiltest2 from "./components/BillingPage2";
import MenuCardManagerBulkSave from "./components/MenuCardManagerBulkSave";
import Bills from "./components/BillingHistoryPage";
import MenuList from "./components/MenuList";
import MenuCardManager from "./components/MenuCardManager";
import AdminDashboardLayout from "./components/AdminDashboardLayout";

const App = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <AuthProvider>
      <Router>
        <div className="App">

          <div className="content">
            <Routes>
              <Route path="/" element={<AdminNext />} />
              <Route
                path="/admin/"
                element={
                  <ProtectedRoute allowedRoles={["ROLE_STAFF", "ROLE_ADMIN"]}>
                    <AdminDashboardLayout
                      handleDrawerToggle={handleDrawerToggle}
                      mobileOpen={mobileOpen}
                    />
                  </ProtectedRoute>
                }
              >
                <Route path="" element={<Home />} />
                <Route
                  path="customer-register"
                  element={<CustomerRegister />}
                />
                <Route path="customers" element={<Customer />} />
                <Route
                  path="settings/brand-manager"
                  element={<BrandManager />}
                />
                <Route path="settings/model-manager" element={<ModelManager />} />

                <Route
                  path="service-packages"
                  element={<ManageServicePackages />}
                />
                <Route
                  path="customer-booking"
                  element={<CustomerBooking />}
                />
                <Route
                  path="test"
                  element={<test />}
                />
                <Route
                  path="menu-card-manager"
                  element={<MenuCardManager />}
                />
                  <Route
                  path="billing-page"
                  element={<BillingPage />}
                />
                 <Route
                  path="bills"
                  element={<Bills />}
                />
                <Route
                  path="menu-list"
                  element={<MenuList />}
                />
                 <Route
                  path="add-menu-bulk"
                  element={<MenuCardManagerBulkSave />}
                />
                 <Route
                  path="all-brand"
                  element={<AllBrand />}
                />
                  <Route
                  path="customer-list"
                  element={<SearchCustomer />}
                />
                  <Route
                  path="bill2"
                  element={<Biiltest2 />}
                />
                <Route
                  path="customer-vehicle-add"
                  element={<CustomerVehicleAdd />}
                />{" "}
                {/* Add the new route */}
                <Route path="admin-register" element={<AdminRegister />} />
              </Route>

              {/* Unauthorized Route */}
              <Route path="unauthorized" element={<Unauthorized />} />
            </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;