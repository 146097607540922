import React, { useState, useEffect } from 'react';
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper, 
    Button, 
    TextField, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    Typography,
    Breadcrumbs,
    Link
} from '@mui/material';

// Sample API call functions to replace with actual API calls
const fetchCustomersFromDB = () => {
    return [
        { id: 1, mobile: '1234567890', name: 'Customer 1', address: '123 Main St', birthday: '1990-01-01', email: 'customer1@example.com', vehicleCount: 2, status: 1 },
        { id: 2, mobile: '0987654321', name: 'Customer 2', address: '456 Elm St', birthday: '1985-05-15', email: 'customer2@example.com', vehicleCount: 1, status: 0 }
    ];
};

const fetchVehiclesForCustomer = (customerId) => {
    const vehiclesData = {
        1: [
            { number: 'ABC-123', color: 'Red', brand: 'Toyota', category: 'CAR' },
            { number: 'XYZ-789', color: 'Blue', brand: 'Honda', category: 'VAN' }
        ],
        2: [
            { number: 'LMN-456', color: 'Black', brand: 'Ford', category: 'JEEP' }
        ]
    };
    return vehiclesData[customerId] || [];
};

const CustomerList = () => {
    const [customers, setCustomers] = useState([]);
    const [editingCustomer, setEditingCustomer] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openVehicleDialog, setOpenVehicleDialog] = useState(false);
    const [openConfirmDeactivateDialog, setOpenConfirmDeactivateDialog] = useState(false); // Confirmation for deactivation
    const [openConfirmActivateDialog, setOpenConfirmActivateDialog] = useState(false); // Confirmation for activation
    const [customerToDeactivate, setCustomerToDeactivate] = useState(null); // Store customer ID for deactivation
    const [customerToActivate, setCustomerToActivate] = useState(null); // Store customer ID for activation
    const [editFormData, setEditFormData] = useState({
        mobile: '',
        name: '',
        address: '',
        birthday: '',
        email: ''
    });
    const [vehicles, setVehicles] = useState([]);

    useEffect(() => {
        const fetchedCustomers = fetchCustomersFromDB(); // Replace with actual API call
        setCustomers(fetchedCustomers);
    }, []);

    const handleStatusToggle = (customerId) => {
        const customer = customers.find(c => c.id === customerId);
        if (customer.status === 1) {
            setCustomerToDeactivate(customerId);
            setOpenConfirmDeactivateDialog(true);
        } else {
            setCustomerToActivate(customerId);
            setOpenConfirmActivateDialog(true);
        }
    };

    const updateCustomerStatus = (customerId, status) => {
        const updatedCustomers = customers.map((customer) =>
            customer.id === customerId ? { ...customer, status } : customer
        );
        setCustomers(updatedCustomers);
        // Optionally, call an API to update the status in the database
    };

    const handleConfirmDeactivation = () => {
        if (customerToDeactivate) {
            updateCustomerStatus(customerToDeactivate, 0); // Update status to inactive (0)
            setCustomerToDeactivate(null);
            setOpenConfirmDeactivateDialog(false);
        }
    };

    const handleCancelDeactivation = () => {
        setOpenConfirmDeactivateDialog(false);
        setCustomerToDeactivate(null);
    };

    const handleConfirmActivation = () => {
        if (customerToActivate) {
            updateCustomerStatus(customerToActivate, 1); // Update status to active (1)
            setCustomerToActivate(null);
            setOpenConfirmActivateDialog(false);
        }
    };

    const handleCancelActivation = () => {
        setOpenConfirmActivateDialog(false);
        setCustomerToActivate(null);
    };

    const handleEditClick = (customer) => {
        setEditingCustomer(customer);
        setEditFormData({
            mobile: customer.mobile,
            name: customer.name,
            address: customer.address,
            birthday: customer.birthday,
            email: customer.email
        });
        setOpenEditDialog(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSaveClick = () => {
        const updatedCustomers = customers.map((customer) =>
            customer.id === editingCustomer.id
                ? { ...customer, ...editFormData }
                : customer
        );
        setCustomers(updatedCustomers);
        setOpenEditDialog(false);
    };

    const handleCancelClick = () => {
        setOpenEditDialog(false);
    };

    const handleVehicleClick = (customerId) => {
        const fetchedVehicles = fetchVehiclesForCustomer(customerId);
        setVehicles(fetchedVehicles);
        setOpenVehicleDialog(true);
    };

    const handleVehicleChange = (index, e) => {
        const { name, value } = e.target;
        const updatedVehicles = vehicles.map((vehicle, i) =>
            i === index ? { ...vehicle, [name]: value } : vehicle
        );
        setVehicles(updatedVehicles);
    };

    const handleVehicleSave = () => {
        setOpenVehicleDialog(false);
    };

    return (
        <div>
          <Typography variant="h4" className="text-left mb-4"></Typography>
            <Typography variant="h4" className="text-left mb-4">Registered Customers</Typography>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/customers">Customer</Link>
                <Typography color="textPrimary">Registered Customers</Typography>
            </Breadcrumbs>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead sx={{ backgroundColor: '#bec7d0', color: 'white' }}>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Mobile</TableCell>
                            <TableCell>Customer Name</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Birthday</TableCell>
                            <TableCell>Email Address</TableCell>
                            <TableCell>Vehicle Count</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Edit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customers.map((customer, index) => (
                            <TableRow
                                key={customer.id}
                                sx={{
                                    backgroundColor: customer.status === 0 ? '#f8d7da' : 'inherit',
                                    '&:hover': { backgroundColor: '#cacaca' }, // Hover effect
                                }}
                            >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{customer.mobile}</TableCell>
                                <TableCell>{customer.name}</TableCell>
                                <TableCell>{customer.address}</TableCell>
                                <TableCell>{customer.birthday}</TableCell>
                                <TableCell>{customer.email}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleVehicleClick(customer.id)}
                                    >
                                        {customer.vehicleCount}
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        color={customer.status === 1 ? 'primary' : 'secondary'}
                                        onClick={() => handleStatusToggle(customer.id)}
                                    >
                                        {customer.status === 1 ? 'Deactivate' : 'Activate'}
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button variant="outlined" onClick={() => handleEditClick(customer)}>
                                        Edit
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Dialog for Editing Customer Details */}
            <Dialog open={openEditDialog} onClose={handleCancelClick}>
                <DialogTitle>Edit Customer Details</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Mobile"
                        name="mobile"
                        value={editFormData.mobile}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Customer Name"
                        name="name"
                        value={editFormData.name}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Address"
                        name="address"
                        value={editFormData.address}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Birthday"
                        name="birthday"
                        value={editFormData.birthday}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Email"
                        name="email"
                        value={editFormData.email}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelClick}>Cancel</Button>
                    <Button onClick={handleSaveClick} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for Vehicle Details */}
            <Dialog open={openVehicleDialog} onClose={() => setOpenVehicleDialog(false)}>
                <DialogTitle>Vehicle Details</DialogTitle>
                <DialogContent>
                    {vehicles.map((vehicle, index) => (
                        <div key={index}>
                            <TextField
                                label="Vehicle Number"
                                name="number"
                                value={vehicle.number}
                                onChange={(e) => handleVehicleChange(index, e)}
                                fullWidth
                                margin="dense"
                            />
                            <TextField
                                label="Color"
                                name="color"
                                value={vehicle.color}
                                onChange={(e) => handleVehicleChange(index, e)}
                                fullWidth
                                margin="dense"
                            />
                            <TextField
                                label="Brand"
                                name="brand"
                                value={vehicle.brand}
                                onChange={(e) => handleVehicleChange(index, e)}
                                fullWidth
                                margin="dense"
                            />
                            <TextField
                                label="Category"
                                name="category"
                                value={vehicle.category}
                                onChange={(e) => handleVehicleChange(index, e)}
                                fullWidth
                                margin="dense"
                            />
                        </div>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenVehicleDialog(false)}>Close</Button>
                    <Button onClick={handleVehicleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog for Deactivation */}
            <Dialog open={openConfirmDeactivateDialog} onClose={handleCancelDeactivation}>
                <DialogTitle>Confirm Deactivation</DialogTitle>
                <DialogContent>
                    Are you sure you want to deactivate this customer?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDeactivation} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDeactivation} color="secondary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog for Activation */}
            <Dialog open={openConfirmActivateDialog} onClose={handleCancelActivation}>
                <DialogTitle>Confirm Activation</DialogTitle>
                <DialogContent>
                    This customer is now deactivated. Do you want to activate this customer?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelActivation} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmActivation} color="secondary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CustomerList;
