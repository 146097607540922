import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Card,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const AdminRegister = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [roleColor, setRoleColor] = useState("");
  const [loading, setLoading] = useState(false);

  // Validation schema
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    role: Yup.string().required("Role selection is required"),
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/register/admin`,
        {
          username: values.username,
          password: values.password,
          role: values.role,
          phone: values.phone,
          email: values.email
        }
      );
      setSnackbarMessage("Admin registered successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      resetForm(); // Clear form after success
    } catch (error) {
      setSnackbarMessage("Registration failed. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleRoleChange = (event) => {
    const selectedRole = event.target.value;
    setRoleColor(getRoleColor(selectedRole));
  };

  const getRoleColor = (role) => {
    switch (role) {
      case "admin":
        return "#dff0d8"; // Light green for Admin
      case "manager":
        return "#d9edf7"; // Light blue for Manager
      case "staff":
        return "#f2dede"; // Light red for Staff
      default:
        return "";
    }
  };

  return (
    <Container
      maxWidth="xl"
      sx={{ padding: 0, margin: 0, marginLeft: 0 }}
      className="mt-1"
    >
      <Card variant="outlined" className="p-4 shadow">
        <Formik
          initialValues={{
            username: "",
            password: "",
            confirmPassword: "",
            role: "",
            phone:"",
            email:""
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" mb={3}>
                <Typography variant="h6" mb={2}>
                  Employee Registration
                </Typography>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="role-label">Role</InputLabel>
                  <Select
                    labelId="role-label"
                    name="role"
                    value={values.role}
                    onChange={(e) => {
                      handleChange(e);
                      handleRoleChange(e);
                    }}
                    onBlur={handleBlur}
                    error={touched.role && Boolean(errors.role)}
                    sx={{ backgroundColor: roleColor }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="ROLE_ADMIN">Admin</MenuItem>
                    <MenuItem value="ROLE_STAFF">Staff</MenuItem>
                  </Select>
                  {touched.role && errors.role && (
                    <Alert severity="error" sx={{ mt: 1 }}>
                      {errors.role}
                    </Alert>
                  )}
                </FormControl>

                <TextField
                  label="Username"
                  variant="outlined"
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.username && Boolean(errors.username)}
                  helperText={touched.username && errors.username}
                  required
                  sx={{ mb: 2 }}
                />

                <TextField
                  label="Password"
                  type="password"
                  variant="outlined"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  required
                  sx={{ mb: 2 }}
                />

                <TextField
                  label="Confirm Password"
                  type="password"
                  variant="outlined"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.confirmPassword && Boolean(errors.confirmPassword)
                  }
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  required
                  sx={{ mb: 2 }}
                />
              </Box>
              <Box display="flex" flexDirection="column" mb={3}>
                <Typography variant="h6" mb={2}>
                  Contact details
                </Typography>

                <Box display="flex" justifyContent="space-between">
                  <TextField
                    label="Mobile"
                    variant="outlined"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.mobile && Boolean(errors.mobile)}
                    helperText={touched.mobile && errors.mobile}
                    required
                    sx={{ flex: 1, mr: 2 }}
                  />
                  <TextField
                    label="Email"
                    variant="outlined"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    sx={{ flex: 1 }}
                  />
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  sx={{
                    width: "200px",
                    backgroundColor: "#343a40",
                    "&:hover": { backgroundColor: "#495057" },
                  }}
                >
                  {loading ? "Registering..." : "Register"}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Card>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminRegister;
