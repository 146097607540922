import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    Container,
    Card,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Breadcrumbs,
    Link,
} from '@mui/material';
import axios from 'axios';

const BrandManager = () => {
    const [newBrand, setNewBrand] = useState('');
    const [error, setError] = useState('');
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [brands, setBrands] = useState([]);  // State to hold all brands

    // Fetch all brands when component mounts
    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/staff/vehicle-brand`,  // Get brands endpoint
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setBrands(response.data);  // Set the fetched brands to the state
            } catch (error) {
                console.error('Error fetching brands:', error.response?.data?.message || error);
                setSnackbar({
                    open: true,
                    message: error.response?.data?.message || 'Failed to fetch brands',
                    severity: 'error',
                });
            }
        };

        fetchBrands();  // Call the fetch function when the component mounts
    }, []);  // Empty dependency array ensures it runs only once when component mounts

    const handleAddBrand = async () => {
        if (!newBrand.trim()) {
            setError('Brand name is required');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/staff/vehicle-brand`,
                { brand: newBrand.trim() },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log('Brand saved successfully:', response.data);
            setSnackbar({
                open: true,
                message: 'Brand saved successfully!',
                severity: 'success',
            });
            setNewBrand('');
            setError('');
            setSuccessDialogOpen(true);

            // Fetch updated list of brands
            const updatedResponse = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/staff/vehicle-brand`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setBrands(updatedResponse.data);  // Update brands state with the new list

        } catch (error) {
            console.error('Error saving brand:', error.response?.data?.message || error);
            setSnackbar({
                open: true,
                message: error.response?.data?.message || 'Failed to save the brand',
                severity: 'error',
            });
        }
    };

    return (
        <Container maxWidth="xl" sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
                Brand Manager
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/settings">Setting</Link>
                <Typography color="textPrimary">Brand Saving</Typography>
            </Breadcrumbs>
            <Box sx={{ padding: 3 }}>
                <Paper elevation={4} sx={{ padding: 4, marginBottom: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        Add New Brand
                    </Typography>

                    <Card variant="outlined" className="p-4 shadow">
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                            <TextField
                                label="New Brand"
                                variant="outlined"
                                value={newBrand}
                                onChange={(e) => setNewBrand(e.target.value)}
                                error={!!error}
                                helperText={error}
                                sx={{ marginRight: 2 }}
                            />
                            <Button
                                variant="contained"
                                onClick={handleAddBrand}
                                sx={{
                                    width: '200px',
                                    backgroundColor: '#343a40',
                                    '&:hover': {
                                        backgroundColor: '#495057',
                                    },
                                }}
                            >
                                Save Brand
                            </Button>
                        </Box>
                    </Card>



                    {/* Brand Table */}
                    <TableContainer component={Paper} sx={{ marginTop: 3 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Brand ID</TableCell>
                                    <TableCell>Brand Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {brands.map((brand, index) => (
                                    <TableRow key={index}>
                                        {/* Display sequential Brand ID starting from 1 */}
                                        <TableCell>{index + 1}</TableCell>  {/* Sequential ID */}
                                        <TableCell>{brand.brand}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Success Dialog */}
                    <Dialog open={successDialogOpen} onClose={() => setSuccessDialogOpen(false)}>
                        <DialogTitle>Brand Saved</DialogTitle>
                        <DialogContent>
                            <Typography>New brand has been successfully saved!</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setSuccessDialogOpen(false)}>OK</Button>
                        </DialogActions>
                    </Dialog>

                    {/* Snackbar for error/success messages */}
                    <Snackbar
                        open={snackbar.open}
                        autoHideDuration={6000}
                        onClose={() => setSnackbar({ ...snackbar, open: false })}
                        message={snackbar.message}
                        severity={snackbar.severity}
                    />

                </Paper>
            </Box>
        </Container>
    );
};

export default BrandManager;
