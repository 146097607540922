import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Autocomplete,
    TextField,
    Button,
    Snackbar,
    Alert,
    CircularProgress,
} from '@mui/material';

const ModelManager = () => {
    const [brands, setBrands] = useState([]); // Store all brands
    const [selectedBrand, setSelectedBrand] = useState(null); // Selected brand
    const [modelName, setModelName] = useState(''); // Model name input
    const [loading, setLoading] = useState(true); // Loading state for brands
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' }); // Snackbar for feedback

    // Fetch vehicle brands when the component mounts
    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/staff/vehicle-brand`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setBrands(response.data); // Assuming the response is an array of brand objects
            } catch (error) {
                console.error('Error fetching brands:', error);
                setSnackbar({
                    open: true,
                    message: 'Failed to fetch brands',
                    severity: 'error',
                });
            } finally {
                setLoading(false);
            }
        };

        fetchBrands();
    }, []);

    // Handle brand selection
    const handleBrandChange = (event, value) => {
        setSelectedBrand(value || null); // Update selected brand
    };

    // Handle model name input
    const handleModelChange = (event) => {
        setModelName(event.target.value);
    };

    // Handle saving model to the database
    const handleSaveModel = async () => {
        if (!selectedBrand) {
            setSnackbar({ open: true, message: 'Please select a brand', severity: 'warning' });
            return;
        }
        if (!modelName.trim()) {
            setSnackbar({ open: true, message: 'Please enter a model name', severity: 'warning' });
            return;
        }

        try {
            console.log("brand id "+selectedBrand.vehicleBrandId)
            const token = localStorage.getItem('token');
            await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/staff/vehicle-models`,
                {
                    vehicleBrandId: selectedBrand.vehicleBrandId, // Assuming the brand object has an `_id` field
                    vehicleModelName: modelName.trim(),
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            
            setModelName(''); // Clear the model name field
            window.location.reload();
            setSnackbar({ open: true, message: 'Model saved successfully!', severity: 'success' });
        } catch (error) {
            console.error('Error saving model:', error);
            setSnackbar({ open: true, message: 'Failed to save model', severity: 'error' });
        }
    };

    // Handle closing the snackbar
    const handleCloseSnackbar = () => {
        setSnackbar({ open: false, message: '', severity: '' });
    };

    return (
        <div style={{ padding: '20px' }}>
            <h1>Manage Vehicle Models</h1>

            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <div style={{ marginBottom: '20px' }}>
                        <Autocomplete
                            value={selectedBrand || null}
                            onChange={handleBrandChange}
                            options={brands}
                            getOptionLabel={(option) => option.brand || 'Unknown Brand'}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Brand" variant="outlined" />
                            )}
                            isOptionEqualToValue={(option, value) => option._id === value?._id}
                        />
                    </div>

                    {selectedBrand && (
                        <>
                            <div style={{ marginBottom: '20px' }}>
                                <TextField
                                    fullWidth
                                    label="Model Name"
                                    value={modelName}
                                    onChange={handleModelChange}
                                    variant="outlined"
                                />
                            </div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveModel}
                                disabled={!modelName.trim()}
                            >
                                Save Model
                            </Button>
                        </>
                    )}
                </>
            )}

            {/* Snackbar for feedback */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ModelManager;
