import React, { useState } from 'react';
import {
    Box,
    Button,
    Container,
    Paper,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Select,
    MenuItem,
} from '@mui/material';

const ManageServicePackages = () => {
    const [menuCardText, setMenuCardText] = useState('');
    const vehicleCategories = ['Car', 'Mid', 'SUV', 'Van'];
    const [menus, setMenus] = useState([]); // Main menus
    const [newMenu, setNewMenu] = useState('');
    const [subServices, setSubServices] = useState([]); // Sub-services
    const [selectedMenu, setSelectedMenu] = useState(''); // Selected Main Menu for Sub-Service
    const [newSubService, setNewSubService] = useState('');
    const [serviceItems, setServiceItems] = useState([]); // Service items
    const [selectedSubService, setSelectedSubService] = useState(''); // Selected Sub-Service for Service Items
    const [newServiceName, setNewServiceName] = useState('');
    const [prices, setPrices] = useState({ Car: '', Mid: '', SUV: '', Van: '' });

    // Function to add a new Main Menu
    const handleAddMenu = () => {
        if (newMenu.trim()) {
            setMenus((prev) => [...prev, { name: newMenu, id: Date.now() }]);
            setNewMenu('');
        }
    };

    // Function to add a new Sub-Service
    const handleAddSubService = () => {
        if (selectedMenu && newSubService.trim()) {
            setSubServices((prev) => [
                ...prev,
                { name: newSubService, id: Date.now(), menuId: selectedMenu },
            ]);
            setNewSubService('');
        }
    };

    // Function to add a new Service Item
    const handleAddServiceItem = () => {
        if (selectedMenu && selectedSubService && newServiceName.trim()) {
            setServiceItems((prev) => [
                ...prev,
                {
                    id: Date.now(),
                    name: newServiceName,
                    prices: { ...prices },
                    menuId: selectedMenu,
                    subServiceId: selectedSubService,
                },
            ]);
            setNewServiceName('');
            setPrices({ Car: '', Mid: '', SUV: '', Van: '' });
        }
    };

    // Function to handle key press for price input to allow only numbers
    const handlePriceChange = (category, value) => {
        const numericValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        setPrices((prev) => ({ ...prev, [category]: numericValue }));
    };

    return (
        <Container maxWidth="xl" sx={{ padding: 4 }}>
            {/* Main Menu Creation */}
            <Paper elevation={4} sx={{ padding: 4, marginBottom: 4 }}>
                <Typography variant="h4">Menu Management</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                    <TextField
                        label="Menu Name"
                        value={newMenu}
                        onChange={(e) => setNewMenu(e.target.value)}
                        sx={{ marginRight: 2 }}
                    />
                    <Button variant="contained" onClick={handleAddMenu}>
                        Save Menu
                    </Button>
                </Box>
            </Paper>

            {/* Sub-Services Management */}
            <Paper elevation={4} sx={{ padding: 4, marginBottom: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Add Sub-Services
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
                    <Select
                        label="Select Main Menu"
                        value={selectedMenu}
                        onChange={(e) => {
                            setSelectedMenu(e.target.value);
                            setSelectedSubService(''); // Reset sub-service selection
                        }}
                        sx={{ marginRight: 2, minWidth: 200 }}
                    >
                        <MenuItem value="">
                            <em>Select Menu</em>
                        </MenuItem>
                        {menus.map((menu) => (
                            <MenuItem key={menu.id} value={menu.id}>
                                {menu.name}
                            </MenuItem>
                        ))}
                    </Select>

                    <TextField
                        label="Sub-Service Name"
                        value={newSubService}
                        onChange={(e) => setNewSubService(e.target.value)}
                        sx={{ marginRight: 2 }}
                    />
                    <Button variant="contained" onClick={handleAddSubService}>
                        Save Sub-Service
                    </Button>
                </Box>
            </Paper>

            {/* Service Item Management */}
            <Paper elevation={4} sx={{ padding: 4, marginBottom: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Add Service Items
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
                    <Select
                        label="Select Main Menu"
                        value={selectedMenu}
                        onChange={(e) => {
                            setSelectedMenu(e.target.value);
                            setSelectedSubService(''); // Reset sub-service selection
                        }}
                        sx={{ marginRight: 2, minWidth: 200 }}
                    >
                        <MenuItem value="">
                            <em>Select Menu</em>
                        </MenuItem>
                        {menus.map((menu) => (
                            <MenuItem key={menu.id} value={menu.id}>
                                {menu.name}
                            </MenuItem>
                        ))}
                    </Select>

                    <Select
                        label="Select Sub-Service"
                        value={selectedSubService}
                        onChange={(e) => setSelectedSubService(e.target.value)}
                        sx={{ marginRight: 2, minWidth: 200 }}
                    >
                        <MenuItem value="">
                            <em>Select Sub-Service</em>
                        </MenuItem>
                        {subServices
                            .filter((subService) => subService.menuId === selectedMenu)
                            .map((subService) => (
                                <MenuItem key={subService.id} value={subService.id}>
                                    {subService.name}
                                </MenuItem>
                            ))}
                    </Select>

                    <TextField
                        label="Service Item Name"
                        value={newServiceName}
                        onChange={(e) => setNewServiceName(e.target.value)}
                        sx={{ marginRight: 2 }}
                    />
                    {vehicleCategories.map((category) => (
                        <TextField
                            key={category}
                            label={`Price (${category})`}
                            value={prices[category]}
                            onChange={(e) => handlePriceChange(category, e.target.value)}
                            sx={{ marginRight: 2, width: 120 }}
                            type="text" // Use text type to control input manually
                        />
                    ))}
                    <Button variant="contained" onClick={handleAddServiceItem}>
                        Add Service Item
                    </Button>
                </Box>
            </Paper>

            {/* Rendering Separate Tables for Each Main Menu */}
            {menus.map((menu) => (
                <Paper key={menu.id} elevation={4} sx={{ padding: 4, marginBottom: 4 }}>
                    <Typography variant="h5">{menu.name}</Typography>
                    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sub-Service</TableCell>
                                    <TableCell>Service Name</TableCell>
                                    {vehicleCategories.map((category) => (
                                        <TableCell key={category}>{category} Price (Rs.)</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subServices
                                    .filter((subService) => subService.menuId === menu.id)
                                    .map((subService) => {
                                        const filteredServices = serviceItems.filter(
                                            (service) => service.subServiceId === subService.id
                                        );
                                        return (
                                            <React.Fragment key={subService.id}>
                                                {filteredServices.map((service) => (
                                                    <TableRow key={service.id}>
                                                        <TableCell>{subService.name}</TableCell>
                                                        <TableCell>{service.name}</TableCell>
                                                        {vehicleCategories.map((category) => (
                                                            <TableCell key={category}>{service.prices[category]}</TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            ))}
        </Container>
    );
};

export default ManageServicePackages;
