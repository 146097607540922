import React, { useState } from 'react';
import {
    Box,
    Button,
    Container,
    Paper,
    Typography,
    Autocomplete,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Breadcrumbs,
    Link,
} from '@mui/material';

const users = [
    { id: 1, name: 'John Doe', mobile: '071-123-4567' },
    { id: 2, name: 'Jane Smith', mobile: '071-987-6543' },
    { id: 3, name: 'Alice Johnson', mobile: '071-456-7890' },
];

const vehicles = [
    { id: 1, name: 'Toyota Camry', userId: 1 },
    { id: 2, name: 'Honda Civic', userId: 2 },
    { id: 3, name: 'Ford Focus', userId: 2 },
];

const mainMenus = [
    { id: 1, name: 'Exterior Services' },
    { id: 2, name: 'Interior Services' },
];

const subMenus = {
    1: [
        { id: 1, name: 'Wash' },
        { id: 2, name: 'Waxing' },
    ],
    2: [
        { id: 3, name: 'Vacuuming' },
        { id: 4, name: 'Deep Cleaning' },
    ],
};

const categories = {
    1: [
        { id: 1, name: 'Basic Wash', price: 1500 },
        { id: 2, name: 'Full Wash', price: 2500 },
    ],
    2: [
        { id: 3, name: 'Interior Vacuum', price: 1000 },
        { id: 4, name: 'Full Interior Clean', price: 2000 },
    ],
};

const CustomerBooking = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedMainMenu, setSelectedMainMenu] = useState('');
    const [selectedSubMenu, setSelectedSubMenu] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [bookings, setBookings] = useState([]);

    const handleUserChange = (event, value) => {
        setSelectedUser(value);
        setSelectedVehicle('');
        setSelectedMainMenu('');
        setSelectedSubMenu('');
        setSelectedCategory('');
    };

    const handleVehicleChange = (event) => {
        setSelectedVehicle(event.target.value);
        setSelectedMainMenu('');
        setSelectedSubMenu('');
        setSelectedCategory('');
    };

    const handleMainMenuChange = (event) => {
        const menuId = event.target.value;
        setSelectedMainMenu(menuId);
        setSelectedSubMenu('');
        setSelectedCategory('');
    };

    const handleSubMenuChange = (event) => {
        const subMenuId = event.target.value;
        setSelectedSubMenu(subMenuId);
        setSelectedCategory('');
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleBookService = () => {
        const selectedService = categories[selectedMainMenu]?.find(cat => cat.id === parseInt(selectedCategory));
        const newBooking = {
            user: selectedUser?.name,
            vehicle: selectedVehicle,
            mainMenu: selectedMainMenu,
            subMenu: selectedSubMenu,
            service: selectedService?.name,
            price: selectedService?.price,
            status: 'Pending',
        };
        setBookings([...bookings, newBooking]);

        // Reset selections after booking
        setSelectedUser(null);
        setSelectedVehicle('');
        setSelectedMainMenu('');
        setSelectedSubMenu('');
        setSelectedCategory('');
    };

    const handleCancelBooking = (index) => {
        const updatedBookings = bookings.filter((_, i) => i !== index);
        setBookings(updatedBookings);
    };

    const handleChangeStatus = (index, newStatus) => {
        const updatedBookings = bookings.map((booking, i) => {
            if (i === index) {
                return { ...booking, status: newStatus };
            }
            return booking;
        });
        setBookings(updatedBookings);
    };

    // Helper function to get main menu name by ID
    const getMainMenuName = (id) => {
        const menu = mainMenus.find(menu => menu.id === parseInt(id));
        return menu ? menu.name : '';
    };

    // Helper function to get sub menu name by ID
    const getSubMenuName = (id) => {
        const subMenu = Object.values(subMenus).flat().find(sub => sub.id === parseInt(id));
        return subMenu ? subMenu.name : '';
    };

    return (
        <Container maxWidth="xl" sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
                Customer Booking
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/settings">Settings</Link>
                <Typography color="textPrimary">Customer Booking</Typography>
            </Breadcrumbs>

            <Paper elevation={4} sx={{ padding: 4, marginBottom: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Book a Service
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Autocomplete
                        options={users}
                        getOptionLabel={(option) => `${option.name} - ${option.mobile}`}
                        onChange={handleUserChange}
                        renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        noOptionsText="No users found"
                    />

                    <select
                        value={selectedVehicle}
                        onChange={handleVehicleChange}
                        style={{ padding: 8, minWidth: 200 }}
                        disabled={!selectedUser}
                    >
                        <option value="">
                            {selectedVehicle ? selectedVehicle : 'Select Vehicle'}
                        </option>
                        {vehicles.filter(v => v.userId === selectedUser?.id).map((vehicle) => (
                            <option key={vehicle.id} value={vehicle.name}>
                                {vehicle.name}
                            </option>
                        ))}
                    </select>

                    <select
                        value={selectedMainMenu}
                        onChange={handleMainMenuChange}
                        style={{ padding: 8, minWidth: 200 }}
                        disabled={!selectedVehicle}
                    >
                        <option value="">
                            {selectedMainMenu ? getMainMenuName(selectedMainMenu) : 'Select Main Menu'}
                        </option>
                        {mainMenus.map((menu) => (
                            <option key={menu.id} value={menu.id}>
                                {menu.name}
                            </option>
                        ))}
                    </select>

                    <select
                        value={selectedSubMenu}
                        onChange={handleSubMenuChange}
                        style={{ padding: 8, minWidth: 200 }}
                        disabled={!selectedMainMenu}
                    >
                        <option value="">
                            {selectedSubMenu ? getSubMenuName(selectedSubMenu) : 'Select Sub Menu'}
                        </option>
                        {subMenus[selectedMainMenu]?.map((subMenu) => (
                            <option key={subMenu.id} value={subMenu.id}>
                                {subMenu.name}
                            </option>
                        ))}
                    </select>

                    <select
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        style={{ padding: 8, minWidth: 200 }}
                        disabled={!selectedSubMenu}
                    >
                        <option value="">
                            {selectedCategory ? categories[selectedMainMenu]?.find(cat => cat.id === parseInt(selectedCategory))?.name : 'Select Service Category'}
                        </option>
                        {categories[selectedMainMenu]?.map((category) => (
                            <option key={category.id} value={category.id}>
                                {category.name} - Rs.{category.price}
                            </option>
                        ))}
                    </select>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBookService}
                        sx={{
                            width: '200px',
                            marginTop: 2,
                        }}
                        disabled={!selectedUser || !selectedVehicle || !selectedMainMenu || !selectedSubMenu || !selectedCategory}
                    >
                        Book Service
                    </Button>
                </Box>
            </Paper>

            <Paper elevation={4} sx={{ padding: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Bookings
                </Typography>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>User</TableCell>
                                <TableCell>Vehicle</TableCell>
                                <TableCell>Main Menu</TableCell>
                                <TableCell>Sub Menu</TableCell>
                                <TableCell>Service</TableCell>
                                <TableCell>Price</TableCell>
                               
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bookings.map((booking, index) => (
                                <TableRow key={index}>
                                    <TableCell>{booking.user}</TableCell>
                                    <TableCell>{booking.vehicle}</TableCell>
                                    <TableCell>{getMainMenuName(booking.mainMenu)}</TableCell>
                                    <TableCell>{getSubMenuName(booking.subMenu)}</TableCell>
                                    <TableCell>{booking.service}</TableCell>
                                    <TableCell>{booking.price}</TableCell>
                                   
                                    <TableCell>
                                    <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleCancelBooking(index)}
                >
                    Cancel
                </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                
                </TableContainer>
            </Paper>
        </Container>
    );
};

export default CustomerBooking;
