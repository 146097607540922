import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Snackbar,
} from '@mui/material';
import axios from 'axios';

const MenuList = () => {
    const [menuData, setMenuData] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

    useEffect(() => {
        const fetchMenuData = async () => {
            try {
                const token = localStorage.getItem('token');
                console.log(localStorage.getItem('token'));  // Check if the token is correctly stored

                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/staff/menu-card`,
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                console.log(response.data);  // Log the response data to see its structure
                setMenuData(response.data);  // Set the state with the API response
            } catch (error) {
                console.error('Error fetching menu data:', error);
                setSnackbar({
                    open: true,
                    message: error.response?.data?.message || 'Failed to load menu data',
                    severity: 'error',
                });
            }
        };
        fetchMenuData();
    }, []);


    return (
        <Container maxWidth="xl" sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
                Menu List
            </Typography>
            <Paper elevation={4} sx={{ padding: 4 }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Menu Card</strong></TableCell>
                                <TableCell><strong>Sub-Menu</strong></TableCell>
                                <TableCell><strong>Service Item</strong></TableCell>
                                <TableCell><strong>Car Price</strong></TableCell>
                                <TableCell><strong>Mid SUV Price</strong></TableCell>
                                <TableCell><strong>Large SUV Price</strong></TableCell>
                                <TableCell><strong>Van Price</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {menuData.length > 0 ? (
                                menuData.map((menuCard) => (
                                    menuCard.packageCategory && menuCard.packageCategory.length > 0 ? (
                                        menuCard.packageCategory.map((packageCategory) => (
                                            packageCategory.servicePackage && packageCategory.servicePackage.length > 0 ? (
                                                packageCategory.servicePackage.map((servicePackage) => (
                                                    <TableRow key={`${menuCard.id}-${packageCategory.id}-${servicePackage.id}`}>
                                                        <TableCell>{menuCard.name}</TableCell>
                                                        <TableCell>{packageCategory.name}</TableCell>
                                                        <TableCell>{servicePackage.name}</TableCell>
                                                        <TableCell>{servicePackage.price?.car || '-'}</TableCell>
                                                        <TableCell>{servicePackage.price?.suv_mid || '-'}</TableCell>
                                                        <TableCell>{servicePackage.price?.suv_large || '-'}</TableCell>
                                                        <TableCell>{servicePackage.price?.van || '-'}</TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow key={menuCard.id}>
                                                    <TableCell colSpan={7} align="center">No service packages available for {menuCard.name}</TableCell>
                                                </TableRow>
                                            )
                                        ))
                                    ) : (
                                        <TableRow key={menuCard.id}>
                                            <TableCell colSpan={7} align="center">No package categories available for {menuCard.name}</TableCell>
                                        </TableRow>
                                    )
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        No menu data available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>


                    </Table>
                </TableContainer>
            </Paper>

            <Snackbar
                open={snackbar.open}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
                autoHideDuration={4000}
            />
        </Container>
    );
};

export default MenuList;
