import React from 'react';
import { Box, Grid, Paper, Typography, CircularProgress, List, ListItem, ListItemText } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CakeIcon from '@mui/icons-material/Cake';
import moment from 'moment';

const Dashboard = () => {
    // Sample employee data
    const employees = [
        { name: "Alice Johnson", birthDate: "1990-10-26" },
        { name: "Bob Smith", birthDate: "1988-04-15" },
        { name: "Charlie Brown", birthDate: "1995-10-26" }
    ];

    // Check for employees with birthdays today
    const today = moment().format("MM-DD"); // Format today's date as MM-DD
    const birthdayEmployees = employees.filter(employee =>
        moment(employee.birthDate).format("MM-DD") === today
    );

    return (
        <Box sx={{ padding: '20px', backgroundColor: '#f3f6f9', minHeight: '100vh' }}>
            <Typography variant="h4" sx={{ color: '#111213', marginBottom: '20px', fontWeight: 'bold' }}>
                Admin Dashboard
            </Typography>

            {/* Birthday Notification */}
            {birthdayEmployees.length > 0 && (
                <Paper sx={{ padding: '20px', backgroundColor: '#FFF3E0', marginBottom: '20px' }}>
                    <Typography variant="h6" color="primary" sx={{ display: 'flex', alignItems: 'center' }}>
                        <CakeIcon sx={{ marginRight: '10px', color: '#FFA726' }} /> 
                        Employee Birthdays Today
                    </Typography>
                    <List dense>
                        {birthdayEmployees.map((employee, idx) => (
                            <ListItem key={idx}>
                                <ListItemText primary={`${employee.name}`} secondary="Happy Birthday!" />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            )}

            <Grid container spacing={3}>
                {/* Dashboard Widgets */}
                {[{
                    icon: <PersonAddIcon color="primary" sx={{ fontSize: 40 }} />,
                    label: "New Customers This Month",
                    value: "30"
                },
                {
                    icon: <LocalCarWashIcon color="primary" sx={{ fontSize: 40 }} />,
                    label: "Total Bookings",
                    value: "102"
                },
                {
                    icon: <AttachMoneyIcon color="primary" sx={{ fontSize: 40 }} />,
                    label: "Revenue",
                    value: "$8,400"
                },
                {
                    icon: <CalendarTodayIcon color="primary" sx={{ fontSize: 40 }} />,
                    label: "Appointments Today",
                    value: "12"
                }].map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Paper sx={{ padding: '20px', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {item.icon}
                            <Typography variant="subtitle1" sx={{ marginTop: '10px', fontWeight: 500 }}>{item.label}</Typography>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>{item.value}</Typography>
                        </Paper>
                    </Grid>
                ))}

                {/* Upcoming Appointments */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ padding: '20px', height: '100%' }}>
                        <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold', color: '#1565C0' }}>
                            Upcoming Appointments
                        </Typography>
                        <List dense>
                            {["10:00 AM - John Doe - Car Wash", "11:30 AM - Jane Smith - Interior Detailing", "1:00 PM - Mike Johnson - Oil Change", "2:30 PM - Sarah Lee - Full Service"].map((appointment, idx) => (
                                <ListItem key={idx} disablePadding>
                                    <ListItemText primary={appointment} />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Grid>

                {/* Monthly Progress */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ padding: '20px', height: '100%' }}>
                        <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold', color: '#1565C0' }}>
                            Monthly Progress
                        </Typography>
                        {[{
                            label: "Customer Acquisition",
                            value: 70,
                            color: "primary"
                        },
                        {
                            label: "Revenue Goal",
                            value: 85,
                            color: "secondary"
                        }].map((progress, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                <Typography sx={{ flex: 1 }}>{progress.label}</Typography>
                                <CircularProgress variant="determinate" value={progress.value} color={progress.color} />
                                <Typography sx={{ marginLeft: '10px', fontWeight: 'bold' }}>{progress.value}%</Typography>
                            </Box>
                        ))}
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;
