import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Snackbar,
    Alert,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';  // Importing DatePicker component
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const BillingHistoryPage = () => {
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(''); // State for selected customer
    const [serviceRecords, setServiceRecords] = useState([]); // State for service records
    const [loading, setLoading] = useState(false); // State for loading
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [startDate, setStartDate] = useState(null);  // State for start date
    const [endDate, setEndDate] = useState(null);      // State for end date

    // Fetch customers when the component mounts
    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/staff/customer/all`, // Your API endpoint for fetching customers
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setCustomers(response.data); // Update the customer data
            } catch (error) {
                console.error('Error fetching customers:', error.response?.data?.message || error);
                setSnackbar({
                    open: true,
                    message: error.response?.data?.message || 'Failed to fetch customers',
                    severity: 'error',
                });
            }
        };

        fetchCustomers(); // Call the fetch function when the component mounts
    }, []);

    // Fetch service records when a customer is selected
    useEffect(() => {
        const fetchServiceRecords = async () => {
            if (selectedCustomer) {
                setLoading(true); // Set loading to true when data is being fetched
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_BASE_URL}/staff/service-records/by-customer`,
                        {
                            params: {
                                customerId: selectedCustomer, // Use query parameter 'customerId'
                                startDate: startDate ? startDate.toISOString() : undefined, // Add start date filter
                                endDate: endDate ? endDate.toISOString() : undefined, // Add end date filter
                            },
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    setServiceRecords(response.data); // Update the service records
                } catch (error) {
                    console.error('Error fetching service records:', error.response?.data?.message || error);
                    setSnackbar({
                        open: true,
                        message: error.response?.data?.message || 'Failed to fetch service records',
                        severity: 'error',
                    });
                } finally {
                    setLoading(false); // Set loading to false after data is fetched
                }
            }
        };

        fetchServiceRecords(); // Fetch service records for the selected customer
    }, [selectedCustomer, startDate, endDate]); // Add startDate and endDate as dependencies

    const handleCloseSnackbar = () => {
        setSnackbar({ open: false, message: '', severity: '' });
    };

    const handleCustomerChange = (event) => {
        setSelectedCustomer(event.target.value);
    };

    return (
        <div>
            <h1>Customer List</h1>

            {/* Combo Box to Select Customer */}
            <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="customer-select-label">Select Customer</InputLabel>
                <Select
                    labelId="customer-select-label"
                    value={selectedCustomer}
                    onChange={handleCustomerChange}
                    label="Select Customer"
                >
                    {customers.map((customer) => (
                        <MenuItem key={customer.id} value={customer.id}>
                            {`${customer.name} (${customer.phone})`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Date Range Picker */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
                    <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={setStartDate}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={setEndDate}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </div>
            </LocalizationProvider>

            {/* Loading or No Data message */}
            {loading ? (
                <div style={{ padding: '20px', textAlign: 'center' }}>
                    <h3>Loading...</h3>
                </div>
            ) : serviceRecords.length === 0 ? (
                <div style={{ padding: '20px', textAlign: 'center' }}>
                    <h3>No data found</h3>
                </div>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell> {/* Add column for row number */}
                                <TableCell>Service Date</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Package</TableCell>
                                <TableCell>Service Cost</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {serviceRecords.map((record, index) => (
                                <TableRow key={record.id}>
                                    <TableCell>{index + 1}</TableCell> {/* Display row number (index + 1) */}
                                    <TableCell>{new Date(record.serviceDate).toLocaleDateString()}</TableCell>
                                    <TableCell>{record.description}</TableCell>
                                    <TableCell>
                                        {record.servicePackages.map((pkg, index) => (
                                            <div key={index}>
                                                <strong>{pkg.name}</strong> - Price: {pkg.price.car} (Car)
                                            </div>
                                        ))}
                                    </TableCell>
                                    <TableCell>{record.serviceCost}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* Snackbar for feedback */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default BillingHistoryPage;
