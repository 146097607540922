import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Paper,
    Typography,
    Select,
    MenuItem,
    Snackbar,
    FormControl,
    InputLabel,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const BillingPage = () => {
    const [menuCards, setMenuCards] = useState([]);
    const [packages, setPackages] = useState([]);
    const [serviceItems, setServiceItems] = useState([]);
    const [selectedMenuCard, setSelectedMenuCard] = useState('');
    const [selectedPackage, setSelectedPackage] = useState('');
    const [vehicleNumber, setVehicleNumber] = useState('');
    const [customerName, setCustomerName] = useState(''); // To display customer name
    const [addedRows, setAddedRows] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [customerLocked, setCustomerLocked] = useState(false);
    const [selectedServiceItem, setSelectedServiceItem] = useState('');
    const [billingDate, setBillingDate] = useState(() => new Date().toISOString().split('T')[0]); // Default to current date
    const [vehicles, setVehicles] = useState([]);
    const [customers, setCustomers] = useState({});

    // Fetch menu cards, vehicles, and customers
    useEffect(() => {
        const token = localStorage.getItem('token');

        const fetchData = async () => {
            try {
                const [menuResponse, vehicleResponse, customerResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/staff/menu-card`, { headers: { Authorization: `Bearer ${token}` } }),
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/staff/car/search-all`, { headers: { Authorization: `Bearer ${token}` } }),
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/staff/customer/all`, { headers: { Authorization: `Bearer ${token}` } }),
                ]);

                setMenuCards(menuResponse.data);
                setVehicles(vehicleResponse.data);

                const customerMap = customerResponse.data.reduce((acc, customer) => {
                    acc[customer._id] = customer.name;
                    return acc;
                }, {});
                setCustomers(customerMap);
            } catch (error) {
                console.error('Error fetching data:', error);
                setSnackbar({ open: true, message: 'Failed to load data', severity: 'error' });
            }
        };

        fetchData();
    }, []);

    const handleMenuCardChange = async (event) => {
        const selectedCardId = event.target.value;
        setSelectedMenuCard(selectedCardId);

        if (selectedCardId) {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/staff/packages/by-menu-card/${selectedCardId}`,
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                setPackages(response.data);
            } catch (error) {
                setSnackbar({ open: true, message: 'Failed to load packages', severity: 'error' });
            }
        } else {
            setPackages([]);
        }
    };

    const handlePackageChange = async (event) => {
        const selectedPkgId = event.target.value;
        setSelectedPackage(selectedPkgId);
        setSelectedServiceItem(''); // Reset service item selection

        if (selectedPkgId) {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/staff/service-package/${selectedPkgId}`,
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                setServiceItems(response.data);
                console.log('data handlePackageChange:', response.data);
            } catch (error) {
                setSnackbar({ open: true, message: 'Failed to load service items', severity: 'error' });
            }
        } else {
            setServiceItems([]);
        }
    };
    const handleDateChange = (event) => {
        setBillingDate(event.target.value);
    };
    const handleServiceItemChange = (event) => {
        const selectedServiceItem = event.target.value;
        setSelectedServiceItem(selectedServiceItem);
    
        if (!vehicleNumber || !selectedMenuCard || !selectedPackage) {
            setSnackbar({
                open: true,
                message: 'Please fill in all fields before selecting a service item!',
                severity: 'error',
            });
            return;
        }
    
        // Find the selected vehicle
        const selectedVehicle = vehicles.find((vehicle) => vehicle.vehicleNumber === vehicleNumber);
        if (!selectedVehicle) {
            setSnackbar({
                open: true,
                message: 'Vehicle not found!',
                severity: 'error',
            });
            return;
        }
    
        // Extract the vehicle category
        const vehicleCategory = selectedVehicle.vehicleCategory?.toLowerCase(); // Convert to lowercase for price matching
        if (!vehicleCategory) {
            setSnackbar({
                open: true,
                message: 'Vehicle category not found!',
                severity: 'error',
            });
            return;
        }
    
        // Find the selected service item
        const selectedService = serviceItems.find((item) => item.name === selectedServiceItem);
    
        if (!selectedService) {
            setSnackbar({
                open: true,
                message: 'Service item not found!',
                severity: 'error',
            });
            return;
        }
    
        // Get the price based on the vehicle category
        const price = selectedService.price?.[vehicleCategory] || 0;
    
        // Add the new row, including serviceItemId
        const newRow = {
            vehicleNumber,
            menuCard: menuCards.find((card) => card.id === selectedMenuCard)?.name || '',
            package: packages.find((pkg) => pkg.id === selectedPackage)?.name || '',
            serviceItem: selectedServiceItem,
            serviceItemId: selectedService.id, // Add the service item ID here
            price,
        };
    
        setAddedRows((prev) => [...prev, newRow]);
        setSnackbar({ open: true, message: 'Row added successfully!', severity: 'success' });
    
        if (!customerLocked) {
            setCustomerLocked(true);
        }
    
        // Clear selected service item
        setSelectedServiceItem('');
    };
    
    const handleSave = async () => {
        if (addedRows.length === 0) {
            setSnackbar({ open: true, message: "No rows to save!", severity: "error" });
            return;
        }
    
        const token = localStorage.getItem("token");
    
        try {
            for (const row of addedRows) {
                // Find the selected vehicle based on vehicleNumber
                const selectedVehicle = vehicles.find(vehicle => vehicle.vehicleNumber === row.vehicleNumber);
                if (!selectedVehicle) {
                    console.error(`Vehicle not found: ${row.vehicleNumber}`);
                    continue;
                }
    
                // Find the customer ID from the selected vehicle
                const customerId = selectedVehicle.customerId;
                if (!customerId) {
                    console.error(`Customer ID not found for vehicle ${row.vehicleNumber}`);
                    continue;
                }
    
                // Construct the service record DTO
                const serviceRecordDTO = {
                    servicePackages: [row.serviceItemId], // Use serviceItemId here
                    serviceCost: row.price,
                    serviceDate: new Date(billingDate), 
                    description: row.serviceItem, 
                    customerId: customerId,  
                    carId: selectedVehicle.id, 
                };
    
                // Send request to save the record
                try {
                    await axios.post(
                        `${process.env.REACT_APP_API_BASE_URL}/staff/service-records`,
                        serviceRecordDTO,
                        { headers: {
                            "Content-Type": "application/json", 
                            Authorization: `Bearer ${token}` } }
                    );
                } catch (error) {
                    console.error("Error saving service record:", error.response ? error.response.data : error.message);
                }
            }
    
            setSnackbar({ open: true, message: "Records saved successfully!", severity: "success" });
           
        } catch (error) {
            console.error("Error saving records:", error);
            setSnackbar({ open: true, message: "Failed to save some or all records.", severity: "error" });
        }
    };
    
    
    

    // Calculate total count and price
    const totalCount = addedRows.length;
    const totalPrice = addedRows.reduce((sum, row) => sum + row.price, 0);

    return (
        <Container maxWidth="lg" sx={{ padding: 4 }}>
            <Paper elevation={4} sx={{ padding: 4, marginBottom: 4 }}>
                <Typography variant="h4" gutterBottom align="center">
                    Billing
                </Typography>
                {/* Date Selection */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 4 }}>
                    <TextField
                        label="Bill Date"
                        type="date"
                        value={billingDate}
                        onChange={handleDateChange}
                        inputProps={{
                            max: new Date().toISOString().split('T')[0], // Restrict future dates
                        }}
                        sx={{
                            width: '200px', // Adjust as needed to reduce width
                            textAlign: 'right',
                        }}
                    />
                </Box>
                {/* Vehicle and Customer Information */}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 4 }}>
                    <Autocomplete
                        options={vehicles}
                        getOptionLabel={(option) => `${option.vehicleNumber}`}
                        value={vehicles.find((vehicle) => vehicle.vehicleNumber === vehicleNumber) || null}
                        onChange={(event, newValue) => {
                            if (!customerLocked) {
                                setVehicleNumber(newValue?.vehicleNumber || '');
                                setCustomerName(customers[newValue?.customerID] || ''); // Set customer name
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Vehicle Number"
                                variant="outlined"
                                fullWidth
                                disabled={customerLocked}
                            />
                        )}
                    />
                    <TextField
                        label="Customer Name"
                        value={customerName}
                        variant="outlined"
                        fullWidth
                        disabled
                    />
                </Box>

                {/* Dropdowns for Menu, Package, and Service Items */}
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                        justifyContent: 'space-between',
                        marginBottom: 4,
                    }}
                >
                    {/* Menu Card */}
                    <FormControl fullWidth sx={{ flex: 1 }}>
                        <InputLabel id="menu-card-label">Menu Card</InputLabel>
                        <Select
                            labelId="menu-card-label"
                            value={selectedMenuCard}
                            onChange={handleMenuCardChange}
                        >
                            <MenuItem value="">
                                <em>Select Menu Card</em>
                            </MenuItem>
                            {menuCards.map((card) => (
                                <MenuItem key={card.id} value={card.id}>
                                    {card.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Package */}
                    <FormControl fullWidth sx={{ flex: 1 }}>
                        <InputLabel id="package-label">Package</InputLabel>
                        <Select
                            labelId="package-label"
                            value={selectedPackage}
                            onChange={handlePackageChange}
                        >
                            <MenuItem value="">
                                <em>Select Package</em>
                            </MenuItem>
                            {packages.map((pkg) => (
                                <MenuItem key={pkg.id} value={pkg.id}>
                                    {pkg.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Service Item */}
                    <FormControl fullWidth sx={{ flex: 1 }}>
                        <InputLabel id="service-item-label">Service Item</InputLabel>
                        <Select
                            labelId="service-item-label"
                            value={selectedServiceItem}
                            onChange={handleServiceItemChange}
                        >
                            <MenuItem value="">
                                <em>Select Service Item</em>
                            </MenuItem>
                            {serviceItems.map((item) => (
                                <MenuItem key={item.id} value={item.name}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {/* Table for Added Entries */}
                <Typography variant="h6" gutterBottom>
                    Added Entries
                </Typography>
                <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Vehicle Number</TableCell>
                                <TableCell>Menu Card</TableCell>
                                <TableCell>Package</TableCell>
                                <TableCell>Service Item</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {addedRows.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.vehicleNumber}</TableCell>
                                    <TableCell>{row.menuCard}</TableCell>
                                    <TableCell>{row.package}</TableCell>
                                    <TableCell>{row.serviceItem}</TableCell>
                                    <TableCell><span data-service-item-id={row.serviceItemId}></span>{row.price}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="delete"
                                            color="error"
                                            onClick={() =>
                                                setAddedRows((prev) => prev.filter((_, i) => i !== index))
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={4} align="right"><strong>Total Count:</strong> {totalCount}</TableCell>
                                <TableCell colSpan={2} align="right"><strong>Total Price:</strong> {totalPrice}</TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <button
                        style={{
                            backgroundColor: '#1976d2',
                            color: '#fff',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: addedRows.length ? 'pointer' : 'not-allowed',
                            opacity: addedRows.length ? 1 : 0.5,
                        }}
                        disabled={!addedRows.length}
                        onClick={handleSave}
                    >
                        SAVE
                    </button>
                </Box>

            </Paper>

            <Snackbar
                open={snackbar.open}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
                autoHideDuration={4000}
            />
        </Container>
    );
};

export default BillingPage;
